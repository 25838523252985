import { ActionIcon, AppShell, Avatar, Box, Card, Flex, Group, Menu, Popover, SimpleGrid, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconFileText, IconGridDots, IconInbox, IconLayoutList, IconLogout, IconMoneybag, IconNetwork, IconWallet } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { ReactComponent as EraLogo } from "../../assets/logo.svg";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/message";

export function ProfileLayout({ children }: { children: any }) {
  return (
    <AppShell
      padding={0}
      styles={(_theme) => ({
        main: { backgroundColor: "transparent" },
      })}>
      <Header />
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
}

const Header = () => {
  const { classes, theme } = useStyles();
  const { user, accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  const logout = async () => {
    try {
      window.location.href = "https://dev-cp.dehub.mn/login";
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const mockdata = [
    { title: "Partner", icon: IconNetwork, color: "teal", link: `https://dev-partner.dehub.mn/access?token=${accessToken}` },
    { title: "User", icon: IconFileText, color: "indigo", link: `https://dev-user.dehub.mn/access?token=${accessToken}` },
  ];

  const mockdata2 = [
    { title: "Network", icon: IconNetwork, color: "grape", link: `https://dev-network.dehub.mn/access?token=${accessToken}` },
    { title: "Invoice", icon: IconFileText, color: "yellow", link: `https://dev-invoice.dehub.mn/access?token=${accessToken}` },
    { title: "Inventory", icon: IconInbox, color: "violet", link: `https://dev-inventory.dehub.mn/access?token=${accessToken}` },
    { title: "Payment", icon: IconWallet, color: "orange", link: `https://dev-payment.dehub.mn/access?token=${accessToken}` },
    { title: "Order", icon: IconLayoutList, color: "cyan", link: `https://dev-order.dehub.mn/access?token=${accessToken}` },
    {
      title: "Finance",
      icon: IconMoneybag,
      color: "blue",
      link: `https://dev-dehub.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
        user?.currentBusiness?.refCode || "unknow"
      }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${user?.currentBusiness?.partner?.businessName || "unknow"}&&email=${
        user?.email || "unknow"
      }&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
    },
  ];

  const items = [...(user?.loginType === "PARTNER" ? mockdata : mockdata2)].map((item) => (
    <UnstyledButton component="a" key={item.title} className={classes.item} href={item.link} bg={item.color + ".0"}>
      <item.icon color={theme.colors[item.color][6]} size="2rem" />
      <Text size="xs" mt={7}>
        {item.title}
      </Text>
    </UnstyledButton>
  ));

  return (
    <AppShell.Header>
      <Group className={classes.header}>
        <Flex align={"center"} gap={8}>
          <a href="https://dev-cp.dehub.mn" className={classes.logoBox}>
            <EraLogo className={classes.logo} />
            <span>
              <div className={classes.logoText}>DeHUB</div>
              <div className={classes.logoDesc}>платформ</div>
            </span>
          </a>
          <Box h={"32px"} style={{ borderRight: "1px solid #868E96" }}></Box>

          <Popover width={user?.loginType === "PARTNER" ? 260 : 400} position="bottom" withArrow shadow="md">
            <Popover.Target>
              <ActionIcon size="lg" variant="transparent">
                <IconGridDots size={"2.0rem"} stroke={1} color={theme.colors.violet[6]} />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown style={{ zIndex: 11 }} p={0}>
              <Card p={"sm"}>
                <SimpleGrid cols={user?.loginType === "PARTNER" ? 2 : 3}>{items}</SimpleGrid>
              </Card>
            </Popover.Dropdown>
          </Popover>
          <Text className={classes.moduleText}>Миний мэдээлэл</Text>
        </Flex>
        <Menu width={260} position="bottom-end" transitionProps={{ duration: 150, exitDuration: 150, transition: "pop-top-right" }}>
          <Menu.Target>
            <UnstyledButton className={classes.user}>
              <Group>
                <Avatar src={user?.avatar} radius="xl" />
                <div style={{ flex: 1 }}>
                  <Text size="sm" fw={500}>
                    {user?.lastName ? user?.lastName[0] : ""}.{user?.firstName ?? "-"}
                  </Text>
                  <Text c="dimmed" size="xs">
                    {user?.currentBusiness?.type === "BUYER" && "Худалдан авагч"}
                    {user?.currentBusiness?.type === "SUPPLIER" && "Нийлүүлэгч"}
                    {user?.currentBusiness === null && "Хэрэглэгч"}
                  </Text>
                </div>
                <IconChevronDown size={14} stroke={1.5} />
              </Group>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item onClick={() => logout()} color="red" leftSection={<IconLogout size={20} stroke={1.5} />}>
              Гарах
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </AppShell.Header>
  );
};

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    backgroundColor: theme.white,
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 20px",
  },
  logo: {
    height: 32,
  },
  logoText: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.colors.violet,
    lineHeight: "18px",
  },
  moduleText: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.colors.violet,
    textTransform: "uppercase",
  },
  logoDesc: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.gray[6],
    textAlign: "end",
  },
  user: {
    padding: theme.spacing.xs,
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },
  userActive: {
    backgroundColor: theme.white,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    height: "90px",
    transition: "box-shadow 150ms ease, transform 100ms ease",
    ":hover": {
      boxShadow: theme.shadows.sm,
    },
  },
}));
