import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const me = async () => {
  const res = await httpRequest.get("/2fa/auth/me");

  return res;
};

export const verify = async (id: string) => {
  const res = await httpRequest.get(`/2fa/auth/${id}/verify`);
  return res;
};

export const login = async (data: any) => await httpRequest.post(`/2fa/auth/login`, data);

export const logout = async () => await httpRequest.get("/2fa/auth/logout");

export const forgot = async (data: any) => await httpRequest.put(`/2fa/auth/forgot`, data);

export const createPin = async (data: any) => await httpRequest.post(`/2fa/auth/pin`, data);

export const updatePin = async (data: any) => await httpRequest.put(`/2fa/auth/pin`, data);

export const test = async () => await httpRequest.get(`/2fa/auth/test`);

export const updateAvatar = async (data: any) => await httpRequest.put("/2fa/auth/profile/avatar", data);

export const dan = async () => await httpRequest.get(`/2fa/auth/profile/dan_verify`);

export const danVerify = async (data: { code: string; expires?: string | number; state: string }) => await httpRequest.get(`/2fa/auth/profile/dan`, data);

export const changePassword = async (data: any) => await httpRequest.post("/2fa/auth/password/change", data);

export const updateProfile = async (data: any) => await httpRequest.put(`/2fa/auth/profile`, data);
